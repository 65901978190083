import React, { useState } from 'react';
import classNames from 'classnames';
import ArrowIcon from 'components/icons/arrow';
import styles from './expandable-container.module.scss';

interface ExpandableContainerProps {
  title: string;
  children: JSX.Element | JSX.Element[] | string;
  isExpanded?: boolean;
  onClick?: () => void;
  className?: string;
  headingClassName?: string;
  startExpanded?: boolean;
}

const ExpandableContainer: React.FC<ExpandableContainerProps> = ({
  title,
  children,
  isExpanded,
  onClick,
  className,
  headingClassName,
  startExpanded,
}) => {
  const [isOpen, setIsOpen] = useState(startExpanded);
  const shouldShowChildren = isExpanded || (isOpen && isExpanded === undefined);

  const handleOnClick = () => {
    if (onClick !== undefined) {
      onClick();
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <section className={classNames({ [className]: !!className, [styles.container]: !!!className })}>
      <div
        className={classNames(styles.heading, { [headingClassName]: !!headingClassName })}
        onClick={handleOnClick}
        data-testid={`container_title_${title.replace(/\s/g, '_')}`}
      >
        <h2 className={styles.title}>{title}</h2>
        <ArrowIcon className={classNames(styles.arrow, { [styles.arrow__open]: shouldShowChildren })} />
      </div>
      <div
        className={styles.content}
        data-testid="container_children"
        style={{
          display: shouldShowChildren ? 'block' : 'none',
        }}
      >
        {children}
      </div>
    </section>
  );
};

export default ExpandableContainer;
