import React, { useState, useEffect, useRef, FC, FormEventHandler, useContext } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { LayoutContext } from '../../../../../frontastic/provider/layout';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Backdrop from '../../../../common/backdrop';
import ChevronIcon from '../../../../icons/chevron';
import SearchIcon from '../../../../icons/search';
import styles from './search.module.scss';

const Search: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const layout = useContext(LayoutContext);
  const ref = useRef<HTMLInputElement>(null);
  const { formatMessage } = useFormat({ name: 'common' });
  const router = useRouter();

  useEffect(() => {
    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [layout.isDesktop]);

  const searchHandler = () => {
    if (!layout.isDesktop) {
      setIsOpen(true);
    }

    if ((isOpen || layout.isDesktop) && searchTerm) {
      // TODO: we might need to revisit how this navigates, because of the delay
      setIsOpen(false);
      router.push({ pathname: '/search', query: { query: searchTerm } });
    }
  };

  const submitHandler: FormEventHandler = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (searchTerm) {
      setIsOpen(false);
      router.push({ pathname: '/search', query: { query: searchTerm } });
    }
  };

  return (
    <div className={classNames(styles.container, { [styles.container__open]: isOpen })} data-testid="search_container">
      {isOpen && <Backdrop className={styles.searchBackdrop} onClick={() => setIsOpen(false)} />}
      {isOpen && (
        <button className={styles.buttonBack} onClick={() => setIsOpen(false)} data-testid="search_back-button">
          <ChevronIcon />
        </button>
      )}

      <button
        className={classNames(styles.btnSearch, {
          [styles.btnSearch__open]: isOpen,
        })}
        onClick={searchHandler}
        data-testid="search_search-button"
        aria-label="Search"
      >
        <SearchIcon className={styles.searchIcon} />
      </button>

      {(layout.isDesktop || isOpen) && (
        <form className={styles.searchForm} onSubmit={submitHandler} autoComplete="off" data-testid="search_form">
          <label htmlFor="search" className={styles.srOnly} data-testid="search_label">
            {formatMessage({ id: 'search', defaultMessage: 'Search' })}
          </label>
          <input
            ref={ref}
            type="text"
            name="search"
            className={styles.searchInput}
            placeholder={formatMessage({ id: 'search', defaultMessage: 'Search' })}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            data-testid="search_input"
          />
        </form>
      )}
    </div>
  );
};

export default Search;
