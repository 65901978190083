import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M1 1H17" stroke="#0B0B0B" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 9H17" stroke="#0B0B0B" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Icon;
