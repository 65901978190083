import { FC } from 'react';
import Image from 'next/image';
import NextLink from 'components/common/NextLink';
import styles from './amplience-footer-social-media-icon.module.scss';
export interface Props {
  linkData: any;
}

const AmplienceFooterSocialMediaIcon: FC<Props> = ({ linkData: linkData }) => {
  const setupIconUrl = () => {
    return (
      'https://' +
      linkData.contentLinkImage.defaultHost +
      '/i/' +
      linkData.contentLinkImage.endpoint +
      '/' +
      linkData.contentLinkImage.name +
      '?qlt=100&fmt=webp'
    );
  };

  return (
    <span className={styles.iconContainer}>
      <NextLink href={linkData.contentLinkUrl}>
        <a data-testid="footer_social_media_link" aria-label={linkData.contentLinkImage.name + ' Social redirect'}>
          <Image
            className={styles.iconImage}
            height={25}
            width={25}
            unoptimized
            src={setupIconUrl()}
            alt={linkData.contentLinkImage.name}
          />
        </a>
      </NextLink>
    </span>
  );
};

export default AmplienceFooterSocialMediaIcon;
