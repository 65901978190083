import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.2395 23H9L10.2353 12.4089H21.7982L23.2395 23Z"
      stroke="#0B0B0B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9157 12.1954C12.9157 10.433 14.3487 9 16.1111 9C17.8736 9 19.3066 10.433 19.3066 12.1954"
      stroke="#0B0B0B"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
