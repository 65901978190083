import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="40" height="40" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 14.8333C9.5 11.8878 11.8878 9.5 14.8333 9.5C17.7789 9.5 20.1667 11.8878 20.1667 14.8333C20.1667 17.7789 17.7789 20.1667 14.8333 20.1667C11.8878 20.1667 9.5 17.7789 9.5 14.8333ZM14.8333 8.5C11.3355 8.5 8.5 11.3355 8.5 14.8333C8.5 18.3311 11.3355 21.1667 14.8333 21.1667C16.4022 21.1667 17.8379 20.5962 18.9442 19.6513L22.6464 23.3536C22.8417 23.5488 23.1583 23.5488 23.3536 23.3536C23.5488 23.1583 23.5488 22.8417 23.3536 22.6464L19.6513 18.9442C20.5962 17.8379 21.1667 16.4022 21.1667 14.8333C21.1667 11.3355 18.3311 8.5 14.8333 8.5Z"
      fill="black"
    />
  </svg>
);

export default Icon;
