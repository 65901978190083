import React, { FC } from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { useFormat } from 'helpers/hooks/useFormat';
import { Locale } from 'frontastic/provider/locale/locales';
import styles from './warningPopUp.module.scss';
interface ChangeLocaleWarningModalProps {
  changeCurrentLocale: any;
  setSelectedLocaleForModal: any;
  setSelectedLocale: any;
  selectedLocaleForModal: Locale;
}

const ChangeLocaleWarningModal: FC<ChangeLocaleWarningModalProps> = ({
  changeCurrentLocale,
  setSelectedLocaleForModal,
  selectedLocaleForModal,
  setSelectedLocale,
}) => {
  const { formatMessage: formatCommon } = useFormat({ name: 'common' });

  return (
    selectedLocaleForModal && (
      <div className={styles.overlay}>
        <div className={styles.modal} data-testid="change-location-modal">
          <div className={styles.content}>
            <ExclamationIcon className={styles.icon} />
            <div className={styles.title}>
              {formatCommon({ id: 'location.change.title', defaultMessage: 'Confirm location change' })}
            </div>
            <div className={styles.description}>
              {formatCommon({
                id: 'location.change.description',
                defaultMessage: 'Changing location will result in your bag being emptied. Do you want to continue?',
              })}
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <button
              data-testid="change-location-cancel-button"
              className={styles.cancelButton}
              onClick={() => {
                setSelectedLocaleForModal(null);
              }}
            >
              {formatCommon({ id: 'cancel', defaultMessage: 'Cancel' })}
            </button>
            <button
              data-testid="change-location-confirm-button"
              className={styles.confirmButton}
              onClick={() => {
                setSelectedLocale(selectedLocaleForModal);
                changeCurrentLocale(selectedLocaleForModal.localeCode);
                setSelectedLocaleForModal(null);
              }}
            >
              {formatCommon({ id: 'change.my.location', defaultMessage: 'Change my location' })}
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default ChangeLocaleWarningModal;
