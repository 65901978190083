import React, { FC, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CheckIcon } from '@heroicons/react/outline';
import { ExclamationIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { useFormat } from 'helpers/hooks/useFormat';
import { useOutsideClick } from 'helpers/hooks/useOutsideClick';
import { useCart } from 'frontastic';
import { LayoutContext } from 'frontastic/provider/layout';
import { LocaleContext } from 'frontastic/provider/locale';
import { Locale } from 'frontastic/provider/locale/locales';
import styles from './storeLocator.module.scss';
import ChangeLocaleWarningModal from './warning-pop-up';

interface StoreLocatorContentProps {
  changeCurrentLocale: any;
  setSelectedLocaleForModal: any;
}

const StoreLocator: FC = () => {
  const router = useRouter();
  const [storeLocatorOpen, setStoreLocatorOpen] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const localeContext = useContext(LocaleContext);
  const layoutContext = useContext(LayoutContext);
  const [selectedLocale, setSelectedLocale] = useState<Locale>(localeContext.currentLocale);
  const { data, destroyCart } = useCart();
  const { formatMessage: formatCommon } = useFormat({ name: 'common' });
  const [selectedLocaleForModal, setSelectedLocaleForModal] = useState<Locale>(null);
  const layout = useContext(LayoutContext);
  const ref = useOutsideClick(() => {
    setStoreLocatorOpen(false);
  });
  const [storeLocatorHeight, setStoreLocatorHeight] = useState(0);

  const changeCurrentLocale = (localeCode) => {
    localeContext.setLocale(localeCode);
    setStoreLocatorOpen(false);
    router.push('/', null, { locale: localeCode }).then(() => {
      destroyCart();
    });
  };

  const createDropdownOption = (locale: Locale) => {
    return locale.country + ' / ' + locale.currencySymbol + locale.currency;
  };

  const createSelectedOption = (locale: Locale) => {
    return locale.countryCode + '/' + locale.currencySymbol + locale.currency;
  };

  useEffect(() => {
    setShowWarning(data?.lineItems?.length > 0);
  }, [data?.lineItems?.length]);

  const StoreLocatorContent: FC<StoreLocatorContentProps> = ({ changeCurrentLocale, setSelectedLocaleForModal }) => {
    return (
      <div className={styles.storeLocator__content}>
        <div className={styles.storeLocator__messageContainer}>
          <span className={styles.storeLocator__selectMesasge}>
            {' '}
            {formatCommon({ id: 'select.location.and.curreny', defaultMessage: 'Select location & currency' })}
          </span>
          {showWarning && (
            <span className={styles.storeLocator__alertMessage} data-testid="change-location-warning-message">
              <ExclamationIcon className={styles.storeLocator__alertMessage__icon} />{' '}
              {formatCommon({
                id: 'changing.country.warning',
                defaultMessage: 'Changing country may result in your bag being emptied.',
              })}
            </span>
          )}
        </div>
        <div
          className={classNames(styles.storeLocator__optionsContainer, {
            [styles.storeLocator__optionsContainer__open]: true,
          })}
        >
          <ul>
            {localeContext.locales
              .filter((locale) => {
                return (
                  (global.localStorage && global.localStorage['show-hidden-locales'] === 'true') ||
                  locale.public === true
                );
              })
              .map((locale: Locale, index, locales) => (
                <li
                  data-testid={`change-location-${locale.localeCode}-option`}
                  className={classNames(
                    styles.storeLocator__optionsContainer__option,
                    {
                      [styles.storeLocator__optionsContainer__option__selected]: locale === selectedLocale,
                    },
                    {
                      [styles.storeLocator__optionsContainer__option__hasBottomBorder]: index !== locales.length - 1,
                    },
                  )}
                  key={locale.localeCode}
                  onClick={() => {
                    if (data.lineItems.length > 0) {
                      setSelectedLocaleForModal(locale);
                      setStoreLocatorOpen(false);
                    } else {
                      setSelectedLocale(locale);
                      changeCurrentLocale(locale.localeCode);
                    }
                  }}
                >
                  {createDropdownOption(locale)}
                  {locale === selectedLocale && (
                    <CheckIcon className={styles.storeLocator__optionsContainer__option__check} />
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <ChangeLocaleWarningModal
        changeCurrentLocale={changeCurrentLocale}
        setSelectedLocaleForModal={setSelectedLocaleForModal}
        selectedLocaleForModal={selectedLocaleForModal}
        setSelectedLocale={setSelectedLocale}
      />
      {!layout.isDesktop ? (
        <StoreLocatorContent
          changeCurrentLocale={changeCurrentLocale}
          setSelectedLocaleForModal={setSelectedLocaleForModal}
        />
      ) : (
        <>
          <div data-testid="storeLocatorContainer" className={styles.selectionContainer}>
            {!layoutContext.isMobile && (
              <NextLink href={'/stores'}>
                <span
                  className={styles.selection}
                  data-testid="store-locator"
                  style={{
                    color: layout.contains.alertBanner
                      ? document.getElementById('alert-banner-message')?.style?.color
                      : 'black',
                  }}
                >
                  {formatCommon({ id: 'store.locator', defaultMessage: 'Store locator' })}
                </span>
              </NextLink>
            )}
            <span
              className={styles.selection}
              data-testid="change-location-desktop"
              style={{
                color: layout.contains.alertBanner
                  ? document.getElementById('alert-banner-message')?.style?.color
                  : 'black',
              }}
              onClick={() => {
                setStoreLocatorHeight(document.getElementById('storeLocatorContainer')?.clientHeight ?? 0);
                setStoreLocatorOpen(!storeLocatorOpen);
              }}
            >
              {createSelectedOption(localeContext.currentLocale)}
            </span>
          </div>
          <div
            id="storeLocator"
            className={classNames(styles.storeLocator, { [styles.storeLocator__open]: storeLocatorOpen })}
            ref={ref}
            style={{ top: storeLocatorHeight + 'px' }}
          >
            <StoreLocatorContent
              changeCurrentLocale={changeCurrentLocale}
              setSelectedLocaleForModal={setSelectedLocaleForModal}
            />
          </div>
        </>
      )}
    </>
  );
};

export default StoreLocator;
