import { FC, useContext } from 'react';
import { AmplienceAdditionalCtas } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './additionalCta.module.scss';

export interface AdditionalCtaProps {
  setIsOpen: any;
  cta: AmplienceAdditionalCtas;
}

const AdditionalCtas: FC<AdditionalCtaProps> = ({ cta, setIsOpen }) => {
  const layout = useContext(LayoutContext);
  const { title, ctas } = cta;

  return (
    <div className={styles.additionalWrapper} data-testid={'additional_cta'}>
      <span className={styles.additionalCtaTitle}>{title}</span>
      <div className={styles.ctasContainer}>
        {ctas &&
          ctas.map((link, index) => (
            <NextLink key={link.link + '-' + index} href={link.link || ''}>
              <a
                data-testid={'cta_link'}
                className={classNames({ [styles.ctasContainer__link]: link.isColourSwatch && !layout.isDesktop })}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <div
                  className={classNames(styles.additionalCta, {
                    [styles.additionalCta__colourSwatch]: link.isColourSwatch,
                  })}
                >
                  <span
                    style={{ backgroundColor: link.backgorundColour }}
                    className={classNames({
                      [styles.additionalCta__colourSwatch__value]: link.isColourSwatch,
                      [styles.additionalCta__value]: !link.isColourSwatch,
                    })}
                  >
                    {!link.isColourSwatch ? link.value : ''}
                  </span>
                </div>
                {link.isColourSwatch && !layout.isDesktop && (
                  <span className={styles.additionalCta__colourSwatch__text}>{link.value}</span>
                )}
              </a>
            </NextLink>
          ))}
      </div>
    </div>
  );
};

export default AdditionalCtas;
