/* eslint-disable */
import React, { FC, Fragment, MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { Transition } from 'react-transition-group';
import { TransitionState } from '../../../../../../types/transition-group/TransitionState';
import { LayoutContext } from '../../../../../frontastic/provider/layout';
import CloseIcon from '../../../../icons/close';
import HamburgerIcon from '../../../../icons/hamburger';
import Drawer from './drawer/index';
import styles from './navigation.module.scss';
import NavigationDropdown from './navigationDropdown';
/* eslint-enable */

export interface NavigationProps {
  headerRef: MutableRefObject<HTMLElement>;
  content: any;
  setMobileNavigationIsOpen: any;
}

const Navigation: FC<NavigationProps> = ({ headerRef, content, setMobileNavigationIsOpen }) => {
  const [openedMenu, setOpenedMenu] = useState<number | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const layout = useContext(LayoutContext);

  const navRef = useRef(null);

  useEffect(() => {
    setMobileNavigationIsOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  return (
    <>
      {!layout.isDesktop && (
        <>
          <button
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            aria-label="Header menu"
            data-testid="navigation-toggle-mobile"
          >
            {!isDrawerOpen ? (
              <HamburgerIcon className={styles.headerIcon} />
            ) : (
              <CloseIcon className={classNames(styles.headerIcon, styles.headerIcon__close)} />
            )}
          </button>
          <Drawer headerRef={headerRef} isOpen={isDrawerOpen} content={content} setIsOpen={setIsDrawerOpen} />
        </>
      )}
      {layout.isDesktop && (
        <nav
          className={styles.navigation}
          onMouseLeave={() => setOpenedMenu(undefined)}
          data-testid="navigation_container"
        >
          {content.menuNavigation.map(({ title, url, colour }, index: number) => (
            <NextLink key={index} href={url || ''}>
              <span
                className={styles.navigationItem}
                style={{ color: colour }}
                onClick={() => setOpenedMenu(undefined)}
                onMouseEnter={() => setOpenedMenu(index)}
              >
                {title}
              </span>
            </NextLink>
          ))}

          {content.menuNavigation.map(({ navigation }, index: number) =>
            navigation ? (
              <Transition
                key={index}
                nodeRef={navRef}
                in={
                  openedMenu === index &&
                  (navigation.ctasColumns !== undefined ||
                    navigation.additionalCtas !== undefined ||
                    navigation.imageCtas?.images?.length > 0)
                }
                timeout={300}
              >
                {(state: TransitionState) => (
                  <div
                    ref={navRef}
                    className={classNames(styles.navigationMenu, {
                      [styles.navigationMenu__open]: state === 'entering' || state === 'entered',
                    })}
                    style={{ top: headerRef.current?.firstElementChild?.clientHeight }}
                    aria-hidden={openedMenu !== index}
                  >
                    <NavigationDropdown navigation={navigation} setIsOpen={setOpenedMenu} />
                  </div>
                )}
              </Transition>
            ) : (
              <Fragment key={index}></Fragment>
            ),
          )}
        </nav>
      )}
    </>
  );
};

export default Navigation;
