import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { AmplienceImageCard } from '@Types/amplience/amplienceComponents';
import NextLink from 'components/common/NextLink';
import { useFormat } from 'helpers/hooks/useFormat';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import styles from './empty-bag-promo-images.module.scss';

export interface ImagesProps {
  imageCard: AmplienceImageCard;
  linkName?: string;
  linkUrl?: string;
}
export interface PromoImagesFetchedProps {
  promoImage: ImagesProps[];
}
const EmptyBagPromoImages: React.FC<PromoImagesFetchedProps> = (props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  return (
    <div className={styles.container} data-testid="empty-bag-promo-images-container">
      <div className={styles.titleSection}>
        <h1>{formatCartMessage({ id: 'shopping.cart.empty', defaultMessage: 'Your shopping cart is empty.' })}</h1>
        <i>{formatCartMessage({ id: 'explore.the.bellow', defaultMessage: 'Explore the below...' })}</i>
      </div>
      <div className={styles.promoImagesSection}>
        {props?.promoImage?.map((promoImage, index) => {
          return (
            <div className={styles.promoImagesContainer} key={index}>
              <div className={styles.imageWrapper}>
                <Image
                  className={styles.image}
                  src={createImageUrlFromAmplienceImageCard(promoImage?.imageCard)}
                  alt={promoImage?.imageCard?.metaTextAleternative}
                  layout="fill"
                  loader={({ src, width }) => amplienceImageLoader('General', src, width)}
                  data-testid="promo_image"
                ></Image>
              </div>
              {promoImage?.linkUrl && (
                <div className={styles.linkWrapper}>
                  <NextLink href={promoImage?.linkUrl} className={styles.link}>
                    {promoImage?.linkName?.toUpperCase()}
                  </NextLink>
                  <ArrowRightIcon width={11} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmptyBagPromoImages;
