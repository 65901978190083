import React, { useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import InputWithLabel from 'components/commercetools-ui/input-with-label';
import Loading from 'components/commercetools-ui/loading';
import { useFormat } from 'helpers/hooks/useFormat';
import { useStorage } from 'helpers/hooks/useStorage';
import { StringHelpers } from 'helpers/stringHelpers';
import { fetchApiHub } from 'frontastic';
import ArrowIcon from '../../icons/arrow';
import styles from './email-sign-up.module.scss';

const EmailSignUp = () => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatNewsletterMessage } = useFormat({ name: 'newsletter' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const [signUpData, setSignUpData] = useState({ firstName: '', lastName: '', email: '' });
  const [error, setError] = useState({ firstName: false, lastName: false, email: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [errorOnSubmit, setErrorOnSubmit] = useState(false);
  const { getItemJson } = useStorage();
  const [firstTimeEntering, setFirstTimeEntering] = useState(true);
  const router = useRouter();

  const validationObject = {
    firstName: signUpData.firstName.length > 0,
    lastName: signUpData.lastName.length > 0,
    email: signUpData.email.length > 0 && StringHelpers.isEmailValid(signUpData.email),
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorOnSubmit(false);
    const value = e.target.value;
    setSignUpData({ ...signUpData, [e.target.name]: value });
    if (e.target.name === 'email')
      setError({ ...error, [e.target.name]: !(value.length > 0 && StringHelpers.isEmailValid(value)) });
    else setError({ ...error, [e.target.name]: value.length === 0 });
  };

  const handleSubmitNewsletter = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const tempError = { firstName: false, lastName: false, email: false };
    if (firstTimeEntering) {
      setFirstTimeEntering(false);
    }
    Object.keys(signUpData).forEach((key) => {
      validationObject[key] ? (tempError[key] = false) : (tempError[key] = true);
    });

    setError(tempError);
    if (!Object.values(tempError).includes(true)) {
      setIsLoading(true);
      await fetchApiHub(
        '/action/subscribe/subscribeByEmail',
        { method: 'POST' },
        {
          auth: getItemJson('cart_token', 'local').access_token,
          user: { ...signUpData, country: router.locale === 'uk' ? 'GB' : router.locale.toUpperCase() },
        },
      )
        .then((response) => {
          if (response.statusCode >= 400) {
            setErrorOnSubmit(true);
          } else {
            setErrorOnSubmit(false);
            setFormSubmitted(true);
          }
          setIsLoading(false);
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className={styles.emailSignUpContainer}>
      <div className={styles.signUpText}>
        <span className={styles.title}>
          {formatNewsletterMessage({
            id: 'headline.fallback',
            defaultMessage: 'Sign up to our newsletter',
          }).toUpperCase()}
        </span>
        <span className={styles.text}>
          {formatNewsletterMessage({
            id: 'description.fallback',
            defaultMessage:
              'Sign up to our newsletter get the latest news, product launches, sales and more from Thomas Pink, straight to your inbox',
          })}
        </span>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isFormSubmitted ? (
            <div>
              <p className={styles.thankYouMessage}>
                {formatNewsletterMessage({
                  id: 'success.fallback.message',
                  defaultMessage: 'Thank you for signing up to our newsletter.',
                })}
              </p>
            </div>
          ) : (
            <div className={styles.signUpForm}>
              <div className={styles.nameSurnameContainer}>
                <InputWithLabel
                  id="firstName"
                  dataTestId="signup-newsletter-firstname"
                  type="text"
                  name="firstName"
                  value={signUpData.firstName}
                  label={formatCommonMessage({ id: 'firstName', defaultMessage: 'First Name' })}
                  onChange={handleChange}
                  error={!firstTimeEntering && error.firstName}
                  errorMessage={formatErrorMessage({
                    id: 'firstName.empty',
                    defaultMessage: 'First name should not be empty',
                  })}
                  className={{
                    container: styles.nameSurnameInput,
                    containerError: styles.emailSignUpContainer__error,
                  }}
                />
                <InputWithLabel
                  id="lastName"
                  dataTestId="signup-newsletter-surname"
                  type="text"
                  name="lastName"
                  value={signUpData.lastName}
                  label={formatCommonMessage({ id: 'lastName', defaultMessage: 'Last Name' })}
                  onChange={handleChange}
                  error={!firstTimeEntering && error.lastName}
                  errorMessage={formatErrorMessage({
                    id: 'lastName.empty',
                    defaultMessage: 'Last name should not be empty',
                  })}
                  className={{ container: styles.nameSurnameInput, containerError: styles.emailSignUpContainer__error }}
                />
              </div>
              <div className={classNames(styles.emailSubmit)}>
                <InputWithLabel
                  id="signUpEemail"
                  dataTestId="signup-newsletter-email"
                  type="email"
                  name="email"
                  value={signUpData.email}
                  label={formatCommonMessage({ id: 'email.enter', defaultMessage: 'Enter your email address' })}
                  onChange={handleChange}
                  error={!firstTimeEntering && error.email}
                  errorMessage={formatErrorMessage({
                    id: 'email.valid',
                    defaultMessage: 'Email format is invalid',
                  })}
                  className={{ container: styles.emailInput, containerError: styles.emailSignUpContainer__error }}
                />
                <button
                  data-testid="signup-newsletter-button"
                  type="button"
                  name="signUp"
                  onClick={handleSubmitNewsletter}
                  className={classNames({ [styles.inactiveButton]: Object.values(validationObject).includes(false) })}
                >
                  {formatAccountMessage({ id: 'sign.up', defaultMessage: 'Join now' })}
                  <ArrowIcon className={styles.svg}></ArrowIcon>
                </button>
              </div>
              {errorOnSubmit && (
                <p className={styles.signupError}>
                  {formatAccountMessage({
                    id: 'sign.up.error',
                    defaultMessage: 'An error occurred on sign up, please try again later',
                  })}
                </p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmailSignUp;
