/* eslint-disable */
import React, { FC, Fragment, MutableRefObject, useEffect, useRef, useState } from 'react';
import { AmplienceHeaderNavigationDropdown } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { TransitionState } from '../../../../../../../types/transition-group/TransitionState';
import Arrowicon from '../../../../../../components/icons/arrow';
import AdditionalCtas from '../navigationSegments/additionalCtas';
import ImageCtas from '../navigationSegments/imageCtas';
import styles from './drawer.module.scss';
import StoreLocator from '../../storeLocator';
import { useContext } from 'react';
import { LocaleContext } from 'frontastic/provider/locale';
import { useFormat } from 'helpers/hooks/useFormat';
import { useRouter } from 'next/router';
import NextLink from '../../../../../common/NextLink';

export interface DrawerProps {
  headerRef: MutableRefObject<HTMLElement>;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  content: {
    menuNavigation: {
      colour: string;
      title: string;
      url: string;
      navigation: AmplienceHeaderNavigationDropdown;
    }[];
  };
}

const Drawer: FC<DrawerProps> = ({ headerRef, isOpen, content, setIsOpen }) => {
  const localeContext = useContext(LocaleContext);
  const { push } = useRouter();
  const { formatMessage: formatCommon } = useFormat({ name: 'common' });

  const [subMenuInd, setSubMenuInd] = useState(-1);
  const [subSubMenuInd, setSubSubMenuInd] = useState(-1);
  const [additionalItemInd, setAdditionalItemInd] = useState(-1);

  const drawerRef = useRef(null);
  const drawerHeight =
    typeof window !== 'undefined'
      ? window.innerHeight - ((headerRef.current?.offsetTop ?? 0) + (headerRef.current?.clientHeight ?? 0))
      : 0;

  const additionalMenuItems = [
    {
      titleToRender:
        localeContext.currentLocale.country +
        ' / ' +
        localeContext.currentLocale.currencySymbol +
        localeContext.currentLocale.currency,
      componentToRender: <StoreLocator />,
      backText: formatCommon({
        id: 'location.and.currency',
        defaultMessage: 'Location & Currency',
      }),
      testId: 'change-location-mobile',
    },
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    setSubMenuInd(-1);
    setSubSubMenuInd(-1);
    setAdditionalItemInd(-1);

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  const MainMenu = () => (
    <div
      className={classNames({ [styles.hidden]: subMenuInd !== -1 || additionalItemInd !== -1 })}
      data-testid="drawer-main_menu"
    >
      {content.menuNavigation.map(({ title, url, colour, navigation }, index) => {
        const hasCtas = navigation.ctasColumns || navigation.additionalCtas || navigation.imageCtas?.images?.length > 0;
        const pushPage = url ? url : '/';

        return (
          <NextLink key={index} href={hasCtas ? '' : url}>
            <div
              className={styles.category}
              style={{ color: colour }}
              onClick={() => {
                setSubMenuInd(hasCtas ? index : -1);
                setSubSubMenuInd(-1);
                if (!hasCtas) {
                  setIsOpen(false);
                }
              }}
              data-testid="drawer-main_menu_item"
              data-type="allMenuNavigations-menuNavigation"
            >
              <div>{title}</div>
              {hasCtas && (
                <button aria-label={'Open ' + title}>
                  <Arrowicon className={styles.category__arrow} />
                </button>
              )}
            </div>
          </NextLink>
        );
      })}

      {additionalMenuItems.map((navigationItem, index) => (
        <div
          key={index}
          className={styles.category}
          style={{ background: 'rgba(225, 186, 182, 0.35)' }}
          onClick={() => setAdditionalItemInd(index)}
          data-testid={navigationItem.testId}
          data-type="allMenuNavigations-additionalNavigationItems"
        >
          <div>
            <span>{navigationItem.titleToRender}</span>
          </div>

          <button aria-label={`Open ${navigationItem.titleToRender}`}>
            <Arrowicon className={styles.category__arrow} />
          </button>
        </div>
      ))}
    </div>
  );

  const buildSubMenus = () => {
    const buildSubSubMenus = (parentMenuIndex: number, navigation: AmplienceHeaderNavigationDropdown) =>
      (navigation.ctasColumns ?? [])
        .map((column) => column.columnSections)
        .flat()
        .map((section, index) => (
          <div
            key={section.sectionTitle + index}
            className={classNames({
              [styles.hidden]: parentMenuIndex !== subMenuInd || index !== subSubMenuInd || additionalItemInd !== -1,
            })}
            data-testid="drawer-sub_sub_menu"
          >
            <div className={styles.subNavTitle} onClick={() => setSubSubMenuInd(-1)}>
              <Arrowicon className={styles.subNavTitle__arrow} />
              <span>{section.sectionTitle}</span>
            </div>
            {section.ctas.map((cta, index) => (
              <NextLink key={cta.linkUrl + '-' + index} href={cta.linkUrl}>
                <span
                  className={styles.ctaContainer}
                  onClick={() => setIsOpen(false)}
                  data-testid="drawer-sub_sub_menu_cta"
                  data-type="subMenuNavigation-activeSubNavigation"
                >
                  {cta.linkText}
                </span>
              </NextLink>
            ))}
          </div>
        ));

    return content.menuNavigation.map(({ navigation, title }, index) => (
      <Fragment key={index}>
        <div
          className={classNames({
            [styles.hidden]: index !== subMenuInd || subSubMenuInd !== -1 || additionalItemInd !== -1,
          })}
          data-testid="drawer-sub_menu"
        >
          <div
            className={styles.subNavTitle}
            onClick={() => {
              setSubMenuInd(-1);
              setSubSubMenuInd(-1);
            }}
            data-type="activeMenuNavigation-subNavTitle"
          >
            <Arrowicon className={styles.subNavTitle__arrow} />
            <span>{title}</span>
          </div>

          {navigation.mainCta && (
            <NextLink href={navigation.mainCta.linkUrl}>
              <span
                className={styles.mainCta}
                data-type="activeMenuNavigation-mainCta"
                onClick={() => setIsOpen(false)}
              >
                <span data-testid="main_cta">{navigation.mainCta.linkText}</span>
                <Arrowicon className={styles.mainCta__arrow} />
              </span>
            </NextLink>
          )}
          {(navigation.ctasColumns ?? [])
            .map((column) => column.columnSections)
            .flat()
            .map(({ sectionTitle }, index) => (
              <div
                key={index}
                className={styles.ctaContainer}
                onClick={() => setSubSubMenuInd(index)}
                data-testid="drawer-sub_menu_item"
              >
                <span>{sectionTitle}</span>
                <Arrowicon className={styles.ctaContainer__arrow} />
              </div>
            ))}

          {(navigation.additionalCtas ?? []).map((cta, index) => (
            <AdditionalCtas key={cta.title + index} cta={cta} setIsOpen={setIsOpen} />
          ))}

          {navigation.imageCtas && <ImageCtas ctas={navigation.imageCtas} setIsOpen={setIsOpen} />}
        </div>
        {buildSubSubMenus(index, navigation)}
      </Fragment>
    ));
  };

  return (
    <Transition nodeRef={drawerRef} in={isOpen} timeout={300}>
      {(state: TransitionState) => (
        <div
          ref={drawerRef}
          className={classNames(styles.drawer, {
            [styles.drawer__open]: state === 'entering' || state === 'entered',
          })}
          style={{ height: `${drawerHeight}px` }}
        >
          <MainMenu />
          {buildSubMenus()}

          {additionalMenuItems.map((item, index) => (
            <div
              key={index}
              className={classNames({ [styles.hidden]: additionalItemInd !== index })}
              data-testid="drawer-additional_item"
            >
              <div className={styles.subNavTitle} onClick={() => setAdditionalItemInd(-1)}>
                <Arrowicon className={styles.subNavTitle__arrow} />
                <span>{item.backText}</span>
              </div>
              {item.componentToRender}
            </div>
          ))}
        </div>
      )}
    </Transition>
  );
};

export default Drawer;
