import React, { FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { useCart } from '../../../../frontastic';
import { SFCredentials } from '../../../../frontastic/actions/account';
import { LayoutContext } from '../../../../frontastic/provider/layout';
import useIsLoggedIn from '../../../../helpers/hooks/useIsLoggedIn';
import { useStorage } from '../../../../helpers/hooks/useStorage';
import MiniCart from '../../../commercetools-ui/cart/mini-cart/miniCart';
import AccountIcon from '../../../icons/account';
import CartIcon from '../../../icons/cart';
import styles from './amplience-header.module.scss';
import Navigation from './navigation/index';
import Search from './search/index';
import StoreLocator from './storeLocator';

export interface Props {
  content: any;
}

const AmplienceHeader: FC<Props> = ({ content }) => {
  const layout = useContext(LayoutContext);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [mobileNavigationIsOpen, setMobileNavigationIsOpen] = useState(false);
  const headerRef = useRef<HTMLElement>(null);
  const [loginUrl, setLoginUrl] = useState('');
  const [signupUrl, setSignupUrl] = useState('');
  const [accountUrl, setAccountUrl] = useState('');
  const [isHidden, setIsHidden] = useState(true);
  const router = useRouter();
  const { data: cart } = useCart();
  const { removeItem } = useStorage();
  const goToProductPage = (_url: string) => router.push(_url);
  const disableMiniCartPages = ['/cart', '/checkout', '/payment', '/confirmation'];
  const mainLogoURL = createImageUrlFromAmplienceImageCard({ contentImage: content?.contentLogo?.contentImage });
  const simpleLogoURL = createImageUrlFromAmplienceImageCard({ contentImage: content?.contentAltLogo?.contentImage });
  const { isLoggedIn, setIsLoggedIn } = useIsLoggedIn(cart);

  useEffect(() => {
    SFCredentials().then((res) => {
      const { consumerKey, sandboxUrl } = res;
      const {
        location: { origin },
      } = window;
      const clientId = consumerKey;
      const redirectUrl = `${origin}/authorise-user`;

      setLoginUrl(
        `${sandboxUrl}/my/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=full`,
      );

      setAccountUrl(`${sandboxUrl}/my/s`);
      setSignupUrl(`${sandboxUrl}/my/CommunitiesSelfReg`);
    });
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      setIsCollapsed(window.scrollY > 50);
    };

    scrollHandler();
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  useEffect(() => {
    layout?.update?.setHeaderCollapsed(isCollapsed);
  }, [isCollapsed]);

  useLayoutEffect(() => {
    const alertBannerHeight = layout?.contains?.alertBanner
      ? document.getElementById('alert-banner')?.offsetHeight
      : layout?.isDesktop
      ? 40
      : 0;
    const navigationHeight = layout?.isMobile ? 70 : 100;
    const headerHeight = (layout.contains.bleedingBanner ? 0 : navigationHeight) + alertBannerHeight;
    document.getElementsByTagName('header')[0].style.height = headerHeight + 'px';
  }, [
    router,
    layout.contains.bleedingBanner,
    layout?.contains?.alertBanner,
    layout?.contains?.narrowAlertBanner,
    layout.isDesktop,
    layout.isLaptop,
    layout.isMobile,
    layout.isTablet,
  ]);

  // const logout = (e) => {
  //   e.preventDefault();

  //   setIsLoggedIn(false);
  //   setIsHidden(true);

  //   removeItem('sf_authorisation', 'local');
  //   removeItem('sf_authorisation_code', 'local');
  // };

  return (
    <header
      ref={headerRef}
      className={classNames(styles.header, {
        [styles.header__collapsed]: isCollapsed,
      })}
    >
      <div
        className={classNames(styles.container, {
          ['isPinkBulletin']: layout?.isPinkBulletin,
          [styles.container__collapsed]: isCollapsed,
        })}
        id="header-container"
        data-testid="header_container"
      >
        <div id="alertBannerPortalTarget" className={styles.alertBannerAndStoreLocatorWrapper}>
          {layout.isDesktop && (
            <div
              id="storeLocatorContainer"
              className={classNames(styles.storeLocatorContainer, {
                [styles.storeLocatorContainer__solidBackground]: !layout?.contains?.alertBanner,
                ['isPinkBulletin']: layout?.isPinkBulletin,
              })}
            >
              <StoreLocator />
            </div>
          )}
        </div>
        <div
          className={classNames(styles.wrapper, {
            [styles.wrapper__collapsed]: isCollapsed,
            [styles.wrapper__mobileNavigationIsOpen]: mobileNavigationIsOpen,
          })}
          id="headerWrapper"
        >
          <Navigation headerRef={headerRef} content={content} setMobileNavigationIsOpen={setMobileNavigationIsOpen} />
          <NextLink href="/">
            <div
              className={classNames(styles.logo, { [styles.logo__collapsed]: isCollapsed })}
              data-testid="header_logo"
            >
              <Image
                unoptimized
                src={(isCollapsed ? simpleLogoURL : mainLogoURL) + '?$rebuild-tp-logo$'}
                alt="thomas-pink-logo"
                data-testid={isCollapsed ? 'tp_simple_logo' : 'tp_logo'}
                layout="fill"
              />
            </div>
          </NextLink>
          <Search />
          {/* <div data-testid="header_account-button">
            <div
              className={classNames(styles.accountDropdownContainer, {
                [styles.accountDropdownContainer__active]: !isHidden,
              })}
              onMouseEnter={() => setIsHidden(false)}
              onMouseLeave={() => setIsHidden(true)}
            >
              <AccountIcon className={styles.accountIcon} />

              <div
                className={classNames(styles.accountDropdownWrapper, {
                  [styles.accountDropdownWrapper__hidden]: isHidden,
                })}
              >
                <ul className={styles.accountDropdown}>
                  {isLoggedIn ? (
                    <>
                      <li>
                        <a href={accountUrl}>My Account</a>
                      </li>
                      <li>
                        <a onClick={(e) => logout(e)}>Logout</a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li>
                        <a href={loginUrl}>Login</a>
                      </li>
                      <li>
                        <a href={signupUrl}>Register</a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div> */}
          {!disableMiniCartPages.some((p) => router.asPath.startsWith(p)) && (
            <div className={styles.cartContainer} onClick={() => layout.update.setMiniCartOpenFunction(true)}>
              <div data-testid="header_cart-button">
                <CartIcon />
              </div>
              {cart?.lineItems?.length > 0 && (
                <div className={styles.bagItemIcon} data-testid="header_cart-text">
                  <span>{cart?.lineItems.length}</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {!disableMiniCartPages.some((p) => router.asPath.includes(p)) && (
        <MiniCart
          cart={cart}
          goToProductPage={goToProductPage}
          show={layout.miniCartOpen && cart !== undefined}
          setShow={layout.update.setMiniCartOpenFunction}
        />
      )}
    </header>
  );
};

export default AmplienceHeader;
