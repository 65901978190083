import { FC } from 'react';
import Image from 'next/image';
import { AmplienceImageCtas } from '@Types/amplience/amplienceComponents';
import NextLink from 'components/common/NextLink';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import Arrowicon from '../../../../../../../components/icons/arrow';
import styles from './imageCtas.module.scss';

export interface ImageCtasProps {
  setIsOpen: any;
  ctas: AmplienceImageCtas;
}

const ImageCtas: FC<ImageCtasProps> = ({ ctas, setIsOpen }) => {
  const { layout, images } = ctas;

  return (
    <>
      {layout === 'inline' && (
        <div className={styles.imageCtasContainerInline} data-testid={'image_ctas_container'}>
          {images &&
            images.map((image, index) => (
              <div
                key={image.title + '-' + index}
                className={styles.cardContainer}
                onClick={() => {
                  setIsOpen(false);
                }}
                data-testid={'image_cta'}
              >
                <NextLink href={image.link || ''}>
                  <div className={styles.imageContainer}>
                    <Image
                      src={createImageUrlFromAmplienceImageCard(image.image)}
                      alt={image.image.metaTextAleternative}
                      layout="fill"
                      loader={({ src, width }) => amplienceImageLoader('General', src, width)}
                      className={styles.imageContainer__image}
                    />
                  </div>
                  <span className={styles.cardContainer__title}>
                    {image.title} <Arrowicon className={styles.arrowIcon} />
                  </span>
                </NextLink>
              </div>
            ))}
        </div>
      )}
      {layout === 'grid' && (
        <div className={styles.imageCtasContainerGrid}>
          {images &&
            images.map((image, index) => (
              <div
                key={image.title + '-' + index}
                className={styles.cardContainer}
                style={{
                  gridColumn: index === 0 || images.length === 2 ? '1/span 2' : '',
                }}
                onClick={() => {
                  setIsOpen(false);
                }}
                data-testid={'image_cta'}
              >
                <NextLink href={image.link || ''}>
                  <div
                    className={styles.imageContainer}
                    style={{
                      paddingTop: images.length === 2 || index === 0 ? '50%' : '100%',
                    }}
                  >
                    <Image
                      src={createImageUrlFromAmplienceImageCard(image.image)}
                      alt={image.image.metaTextAleternative}
                      layout="fill"
                      loader={({ src, width }) => amplienceImageLoader('General', src, width)}
                      className={styles.imageContainer__image}
                    />
                  </div>
                  <span className={styles.cardContainer__title}>
                    {image.title} <Arrowicon className={styles.arrowIcon} />
                  </span>
                </NextLink>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default ImageCtas;
