import { AmplienceImageCard } from '../../../types/amplience/amplienceComponents';
export const getContentTypeFromSchemaFormated = (schema = ''): string => {
  // return camel cased content type from schema
  const contentType = schema.split('/');

  return contentType[contentType.length - 1]
    .split('-')
    .map((word, index) => {
      return index === 0 ? word : word[0].toUpperCase() + word.substring(1, word.length);
    })
    .join('');
};

export const getContentTypeFromSchema = (schema = ''): string => {
  const contentType = schema.split('/');
  return contentType[contentType.length - 1];
};

export const createImageUrlFromAmplienceImageCard = (imageCard: AmplienceImageCard): string => {
  return `https://${imageCard?.contentImage?.defaultHost}/i/${imageCard.contentImage?.endpoint}/${encodeURIComponent(
    imageCard?.contentImage?.name,
  )}`;
};
