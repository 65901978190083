import React, { useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import ExpandableContainer from 'components/common/expandable-container';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic';
import { LayoutContext } from 'frontastic/provider/layout';
import { LocaleContext } from 'frontastic/provider/locale';
import { Locale } from 'frontastic/provider/locale/locales';
import ChangeLocaleWarningModal from '../../amplience-header/storeLocator/warning-pop-up';
import styles from './footer-store-locator.module.scss';

export const FooterStoreLocator = () => {
  const router = useRouter();
  const [storeLocatorOpen, setStoreLocatorOpen] = useState(false);
  const [storeLocatorOptionsOpen, setStoreLocatorOptionsOpen] = useState(false);
  const localeContext = useContext(LocaleContext);
  const [selectedLocale, setSelectedLocale] = useState<Locale>(localeContext.currentLocale);
  const { data, destroyCart } = useCart();
  const { formatMessage: formatCommon } = useFormat({ name: 'common' });
  const [selectedLocaleForModal, setSelectedLocaleForModal] = useState<Locale>(null);
  const { isDesktop } = useContext(LayoutContext);

  const changeCurrentLocale = (localeCode) => {
    localeContext.setLocale(localeCode);
    setStoreLocatorOpen(false);
    destroyCart().then(() => {
      router.push('/', null, { locale: localeCode });
    });
  };

  const createDropdownOption = (locale: Locale) => {
    return locale.country + ' / ' + locale.currencySymbol + locale.currency;
  };

  const StoreLocatorOptions = () => {
    return (
      <ul>
        {localeContext.locales
          .filter((locale) => {
            return (
              (global.localStorage && global.localStorage['show-hidden-locales'] === 'true') || locale.public === true
            );
          })
          .map((locale: Locale, index, locales) => (
            <li
              data-testid={`storeLocatorDropdownOption-${locale.localeCode}`}
              className={classNames(
                styles.storeLocator__optionsContainer__option,
                {
                  [styles.storeLocator__optionsContainer__option__selected]: locale === selectedLocale,
                },
                {
                  [styles.storeLocator__optionsContainer__option__hasBottomBorder]: index !== locales.length - 1,
                },
              )}
              key={locale.localeCode}
              onClick={() => {
                if (data.lineItems.length > 0) {
                  setSelectedLocaleForModal(locale);
                  setStoreLocatorOpen(false);
                } else {
                  setSelectedLocale(locale);
                  changeCurrentLocale(locale.localeCode);
                }
              }}
            >
              {createDropdownOption(locale)}
              {locale === selectedLocale && (
                <CheckIcon className={styles.storeLocator__optionsContainer__option__check} />
              )}
            </li>
          ))}
      </ul>
    );
  };

  return (
    <>
      <ChangeLocaleWarningModal
        changeCurrentLocale={changeCurrentLocale}
        setSelectedLocaleForModal={setSelectedLocaleForModal}
        selectedLocaleForModal={selectedLocaleForModal}
        setSelectedLocale={setSelectedLocale}
      />
      {isDesktop ? (
        <div className={styles.storeLocatorWrapper}>
          <p>{formatCommon({ id: 'your.location.and.currency', defaultMessage: 'Your Location And Currency' })}</p>
          <button
            data-testid="storeLocatorDropdown"
            className={styles.storeLocator__selectedLocation}
            onClick={() => {
              setStoreLocatorOptionsOpen(!storeLocatorOptionsOpen);
            }}
          >
            <span
              className={styles.storeLocator__selectedLocation__text}
              data-testid="storeLocatorDropdownSelectedOption"
            >
              {createDropdownOption(localeContext.currentLocale)}
            </span>
            {storeLocatorOptionsOpen ? (
              <ChevronUpIcon className={styles.storeLocator__selectedLocation__chevron} />
            ) : (
              <ChevronDownIcon className={styles.storeLocator__selectedLocation__chevron} />
            )}
          </button>
          <div
            className={classNames(styles.storeLocator__optionsContainer, {
              [styles.storeLocator__optionsContainer__open]: storeLocatorOptionsOpen,
            })}
          >
            <StoreLocatorOptions />
          </div>
        </div>
      ) : (
        <ExpandableContainer
          title={createDropdownOption(localeContext.currentLocale)}
          className={styles.expandableContainer}
          headingClassName={styles.expandableContainerText}
        >
          <div className={styles.footerSectionWrapper}>
            <StoreLocatorOptions />
          </div>
        </ExpandableContainer>
      )}
    </>
  );
};
