import { useEffect, useState } from 'react';
import JWT from 'jsonwebtoken';
import { useStorage } from './useStorage';

const useIsLoggedIn = (data = {}) => {
  const { getItemJson } = useStorage();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const accountToken = getItemJson('sf_authorisation', 'local');

    if (accountToken) {
      const decodedJWT = JWT.decode(accountToken.id_token);
      const currentDate = (new Date().getTime() + 1)?.toString()?.substring(0, 10);
      const isLoggedIn = decodedJWT && Number(currentDate) < decodedJWT.exp;

      setIsLoggedIn(isLoggedIn);
    } else {
      setIsLoggedIn(false);
    }
  }, [data]);

  return { isLoggedIn, setIsLoggedIn };
};

export default useIsLoggedIn;
