import { FC } from 'react';
import Image from 'next/image';
import EmailSignUp from 'components/frontastic-ui/email-sign-up';
import AmexIcon from 'components/icons/amex';
import ApplePayIcon from 'components/icons/applepay';
import MasterCardIcon from 'components/icons/mastercard';
import PayPalIcon from 'components/icons/paypal';
import VisaIcon from 'components/icons/visa';
import { useFormat } from 'helpers/hooks/useFormat';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import AmplienceFooterNavigationItemContainer from './amplience-footer-navigation-item';
import AmplienceFooterSocialMediaIcon from './amplience-footer-social-media-icon';
import styles from './amplience-footer.module.scss';
import { FooterStoreLocator } from './footer-store-locator';
export interface Props {
  content: any;
}

const AmplienceFooter: FC<Props> = ({ content }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <div>
      <EmailSignUp />
      <div className={styles.wrapper} data-testid="amplience-footer-container">
        <hr className={styles.line} />
        <div className={styles.linksContainer}>
          <div className={styles.menuContainer}>
            {content.footerSections?.map((footerSection, i) => {
              return (
                <div key={i}>
                  <AmplienceFooterNavigationItemContainer footerSection={footerSection} />
                </div>
              );
            })}
          </div>
          <div className={styles.locationAndSocialLinksContainer}>
            <div className={styles.locationContainer}>
              <FooterStoreLocator />
            </div>
            <div className={styles.socialsContainer}>
              {content.metaSocialProfileLinks?.map((linkData) => {
                return (
                  <div key={linkData.contentLinkUrl}>
                    <AmplienceFooterSocialMediaIcon linkData={linkData} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.logoAndPayments}>
          <div className={styles.logo}>
            <div>
              <Image
                src={createImageUrlFromAmplienceImageCard({ contentImage: content.logo }) + '?$rebuild-tp-logo$'}
                alt="thomas-pink-logo"
                className={styles.logoImage}
                objectFit="contain"
                layout="fill"
                unoptimized
              />
            </div>
            <span className={styles.logoText}>
              {formatMessage({ id: 'thomas-pink.name.footer', defaultMessage: '© Thomas Pink' }) +
                ' ' +
                new Date().getFullYear()}
            </span>
          </div>
          <div className={styles.payments}>
            <AmexIcon />
            <MasterCardIcon />
            <VisaIcon />
            <ApplePayIcon />
            <PayPalIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmplienceFooter;
