import { FC } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import styles from './backdrop.module.scss';

export interface BackdropProps {
  className?: string;
  onClick?: () => void;
}

const Backdrop: FC<BackdropProps> = ({ className, onClick }) => {
  return typeof window !== 'undefined' ? (
    createPortal(
      <div className={classNames(className, styles.backdrop)} onClick={onClick} data-testid="backdrop" />,
      document.getElementById('__next'),
    )
  ) : (
    <></>
  );
};

export default Backdrop;
