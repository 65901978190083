import { FC } from 'react';
import { AmplienceHeaderNavigationDropdown } from '@Types/amplience/amplienceComponents';
import NextLink from 'components/common/NextLink';
import ArrowIcon from '../../../../../../components/icons/arrow';
import AdditionalCtas from '../navigationSegments/additionalCtas';
import ImageCtas from '../navigationSegments/imageCtas';
import styles from './navigationDropdown.module.scss';

export interface NavigationDropdownProps {
  setIsOpen: any;
  navigation: AmplienceHeaderNavigationDropdown;
}

const NavigationDropdown: FC<NavigationDropdownProps> = ({ navigation, setIsOpen }) => {
  const { mainCta, ctasColumns, additionalCtas, imageCtas } = navigation;

  const hasTwoRows = (columns) => {
    return columns.find((column) => column.columnSections.length > 1);
  };

  const navigationGrid = (columns) => {
    return columns.map(
      (column, columnIndex) =>
        column.columnSections &&
        column.columnSections.map((section, sectionIndex) => (
          <div
            key={'section' + columnIndex + '-' + sectionIndex}
            data-testid={'column_section'}
            className={styles.columnSection}
            style={{ gridColumnStart: columnIndex + 1, gridRowStart: sectionIndex + 1 }}
          >
            <span className={styles.sectionTitle}>{section.sectionTitle}</span>
            {section.ctas &&
              section.ctas.map((cta, index) => (
                <div
                  key={cta.linkText + '-' + index}
                  data-testid={'section_link'}
                  className={styles.sectionCta}
                  onClick={() => {
                    setIsOpen(undefined);
                  }}
                >
                  <NextLink href={cta.linkUrl || ''}>{cta.linkText}</NextLink>
                </div>
              ))}
          </div>
        )),
    );
  };
  return (
    <div className={styles.subCategoryContainer} data-testid="subcategories_container">
      <div className={styles.ctasContainer}>
        {mainCta && (
          <div
            className={styles.mainCta}
            onClick={() => {
              setIsOpen(undefined);
            }}
            data-testid="main_cta"
          >
            <NextLink href={mainCta.linkUrl || ''}>{mainCta.linkText}</NextLink>
            <ArrowIcon className={styles.mainCta__arrow} />
          </div>
        )}
        {ctasColumns && (
          <div
            className={styles.columnsContainer}
            style={{ gridTemplateRows: `${hasTwoRows(ctasColumns) ? 'repeat(2, 1fr)' : ''}` }}
          >
            {ctasColumns && navigationGrid(ctasColumns)}
          </div>
        )}
        {additionalCtas &&
          additionalCtas.map((cta, index) => (
            <AdditionalCtas cta={cta} key={cta.title + '-' + index} setIsOpen={setIsOpen} />
          ))}
      </div>
      <ImageCtas ctas={imageCtas} setIsOpen={setIsOpen} />
    </div>
  );
};

export default NavigationDropdown;
