import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@headlessui/react';
import { XIcon, ArrowRightIcon } from '@heroicons/react/outline';
import { Cart } from '@Types/cart/Cart';
import { LineItem } from '@Types/cart/LineItem';
import classNames from 'classnames';
import NextLink from 'components/common/NextLink';
import { LayoutContext } from 'frontastic/provider/layout';
import { LocaleContext } from 'frontastic/provider/locale';
import { useCart } from '../../../../frontastic';
import { fetchContentByKey } from '../../../../frontastic/actions/amplience';
import TagManager from '../../../../helpers/googleTagManager/googleTag';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { useOutsideClick } from '../../../../helpers/hooks/useOutsideClick';
import Price from '../../price';
import ItemList from '../itemList';
import EmptyBagPromoImages, { PromoImagesFetchedProps } from './empty-bag-promo-images';
import styles from './miniCart.module.scss';

interface Props {
  readonly cart?: Cart;
  readonly show: boolean;
  readonly setShow?: (show: boolean) => void;
  readonly goToProductPage?: (_url: string) => void;
}
const MiniCart = ({ cart, show, setShow, goToProductPage }: Props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { updateItem, removeItem } = useCart();
  const ref = useOutsideClick(() => setShow(false));
  const router = useRouter();
  const gotToUrl = (_url: string) => router.push(_url);
  const layout = useContext(LayoutContext);
  const locale = useContext(LocaleContext);
  const promoImagesKey = 'empty-bag-promo-images';
  const [data, setData] = useState<PromoImagesFetchedProps>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const tagManager = new TagManager();

  function handleButtonClick(event) {
    setShow(false);
    const target = event.target;
    if (target.closest(`.${styles.viewBag}`)) {
      gotToUrl('/cart');
    } else if (target.closest(`.${styles.goToCheckout}`)) {
      gotToUrl('/checkout');
    }
  }

  useEffect(() => {
    async function fetchEmptyBagPromoImages(key: string) {
      const result = await fetchContentByKey(key);
      setData(result.data.content);
    }
    fetchEmptyBagPromoImages(promoImagesKey);
  }, []);

  useEffect(() => {
    if (show) tagManager.viewCart('Mini Cart', cart);
  }, [show]);

  const productPrice = cart?.lineItems?.reduce((a, b: LineItem) => {
    if (b.discountedPrice) {
      return a + b.discountedPrice.centAmount * b.count;
    } else {
      return a + b.price.centAmount * b.count;
    }
  }, 0);
  const shipping = cart?.shippingInfo?.price;
  const total = productPrice + (shipping?.centAmount ?? 0);

  const componentWithDivider = (child: JSX.Element, noPadding?: boolean) => {
    return (
      <>
        <hr />
        <div className={styles.divider} style={{ padding: noPadding && '0px' }}>
          {child}
        </div>
      </>
    );
  };

  useEffect(() => {
    let sum = 0;
    cart?.lineItems?.forEach((lineItem) => {
      sum += lineItem?.count;
    });
    setTotalCount(sum);
  }, [cart?.lineItems]);

  return (
    show && (
      <div className={classNames({ [styles.backdrop]: show })} data-testid="mini-cart-backdrop">
        <div data-testid="mini-cart" ref={ref}>
          <Transition
            as={Fragment}
            show={show}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div data-testid="mini-cart_container" className={styles.container}>
              <h1 className={styles.header}>
                <NextLink href="/cart">
                  <span>
                    {formatCartMessage({ id: 'cart.shopping.bag', defaultMessage: 'Shopping Bag' })} ({totalCount}{' '}
                    {formatCartMessage({ id: 'cart.items', defaultMessage: 'Items' })})
                  </span>
                </NextLink>
                <XIcon
                  data-testid="mini-cart-close"
                  width={25}
                  className="cursor-pointer"
                  onClick={() => setShow(false)}
                />
              </h1>

              {cart?.lineItems?.length > 0 ? (
                <>
                  <div className={styles.itemList}>
                    <ItemList
                      cart={cart}
                      editItemQuantity={updateItem}
                      goToProductPage={goToProductPage}
                      removeItem={(lineItemId: string) => removeItem(lineItemId)}
                      itemStyle="miniCart"
                    />
                  </div>
                  <div className={styles.totalsWithButtons}>
                    {!layout.isMobile &&
                      componentWithDivider(
                        <div className={styles.subtotal} data-testid="miniCart-subtotal">
                          {formatCartMessage({ id: 'cart.subtotal', defaultMessage: 'Subtotal' })}
                          <span>
                            <Price
                              price={{
                                centAmount: productPrice,
                                currencyCode: cart?.sum?.currencyCode,
                              }}
                            />
                          </span>
                        </div>,
                      )}
                    {!layout.isMobile &&
                      componentWithDivider(
                        <div className={styles.delivery} data-testid="miniCart-delivery">
                          <div>
                            {formatCartMessage({
                              id: 'cart.delivery',
                              defaultMessage: 'Delivery',
                            })}
                          </div>
                          <span>
                            {shipping ? (
                              <Price
                                price={{
                                  centAmount: shipping?.centAmount,
                                  currencyCode: cart?.sum?.currencyCode,
                                }}
                                className={styles.amount}
                              />
                            ) : (
                              formatCartMessage({ id: 'tbc', defaultMessage: 'TBC' })
                            )}
                          </span>
                        </div>,
                      )}
                    {!layout.isMobile &&
                      componentWithDivider(
                        <div className={styles.total} data-testid="mini-cart_estimated_totals">
                          <div className={styles.estTotalWithVat}>
                            <div>
                              {formatCartMessage({
                                id: 'estimatedTotal',
                                defaultMessage: 'Estimated total',
                              })}
                            </div>
                            {locale.currentLocale.countryCode === 'UK' && (
                              <div className={styles.vat}>
                                (
                                {formatCartMessage({
                                  id: 'includeVat',
                                  defaultMessage: 'Incl. vat',
                                })}
                                )
                              </div>
                            )}
                          </div>
                          <span>
                            <Price
                              price={{
                                centAmount: total,
                                currencyCode: cart?.sum?.currencyCode,
                              }}
                              className="text-xl font-bold"
                            />
                          </span>
                        </div>,
                      )}
                    {componentWithDivider(
                      <div className={styles.buttons} data-testid="mini-cart-buttons" onClick={handleButtonClick}>
                        <div className={styles.viewBag}>
                          <button data-testid="mini-cart_cart-button">
                            {formatCartMessage({ id: 'cart.viewBag', defaultMessage: 'View Bag' }).toUpperCase()}
                          </button>
                          <ArrowRightIcon width={11} />
                        </div>
                        <div className={styles.goToCheckout}>
                          <button data-testid="mini-cart_checkout-button">
                            {formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' }).toUpperCase()}
                          </button>
                          <ArrowRightIcon width={11} />
                        </div>
                      </div>,
                      true,
                    )}
                    {/* TODO CHANGE TO APPLE PAY */}
                    {componentWithDivider(<div></div>)}
                  </div>
                </>
              ) : (
                <EmptyBagPromoImages promoImage={data?.promoImage} />
              )}
            </div>
          </Transition>
        </div>
      </div>
    )
  );
};

export default MiniCart;
