import { FC, useContext } from 'react';
import { AmplienceColumnSection } from '@Types/amplience/amplienceComponents';
import ExpandableContainer from 'components/common/expandable-container';
import NextLink from 'components/common/NextLink';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './amplience-navigation-item-container.module.scss';
export interface Props {
  footerSection: AmplienceColumnSection;
}

const AmplienceFooterNavigationItemContainer: FC<Props> = ({ footerSection }) => {
  const { isDesktop } = useContext(LayoutContext);

  return (
    <div>
      {!isDesktop ? (
        <>
          <ExpandableContainer title={footerSection.sectionTitle} className={styles.expandableContainer}>
            <div className={styles.footerSectionWrapper}>
              {footerSection?.ctas?.map((cta, i) => {
                return (
                  <NextLink key={i} href={cta.linkUrl}>
                    <p className={styles.footerSectionLink}>{cta.linkText}</p>
                  </NextLink>
                );
              })}
            </div>
          </ExpandableContainer>
        </>
      ) : (
        <div className={styles.footerSectionWrapper}>
          <h5 className={styles.footerSectionTitle}>{footerSection?.sectionTitle}</h5>
          {footerSection?.ctas?.map((cta, i) => {
            return (
              <NextLink key={i} href={cta.linkUrl}>
                <p className={styles.footerSectionLink}>{cta.linkText}</p>
              </NextLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AmplienceFooterNavigationItemContainer;
